// ===============================|| Blank Layout ||=============================== //
<script setup lang="ts">
import { RouterView } from "vue-router";
const title = ref("MAY SOCHIC");
useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `MAY SOCHIC`
      : "MAY SOCHIC";
  },
});
</script>
<template>
  <v-app>
    <RouterView />
  </v-app>
</template>
